import React from 'react';
import { useSetRecoilState } from 'recoil';
import { useLazyQuery, gql } from '@apollo/client';

import { errorsCounterAtom } from './atoms';
import useOrderFilters from 'hooks/orders/useOrderFilters';
import useSettings from 'hooks/useSettings'

const QUERY_COUNT = gql`
  query countByStatus($data: ListOrdersInputExt) {
    countByStatus(data: $data)
  }
`

function TabTitleCounter() {
  const { filters } = useOrderFilters();
  const setCounter = useSetRecoilState(errorsCounterAtom)
  const { date } = filters || {};
  const [settings] = useSettings()

  const dateFilters = React.useMemo(() => {
    if (date?.filter(Boolean).length) {
      return { from: extractDate(date[0]), to: extractDate(date[1]) };
    }
    return {};
  }, [date]);

  const filter = React.useMemo(() => {
    return {
      statuses: ['hold', 'failed', 'pending'],
      ...dateFilters,
      withoutQa: !settings?.qa
    };
  }, [dateFilters, settings]);

  const [getCounter, { data, refetch }] = useLazyQuery(
    QUERY_COUNT,
    {
      variables: { data: { filter, range: [0, 1000] } }
    }
  );

  React.useEffect(() => {
    if (dateFilters.from && dateFilters.to) {
      getCounter();
    }
  }, [dateFilters, getCounter]);

  React.useEffect(() => {
    const timer = setInterval(async () => {
      refetch();
    }, 10000);
    return () => {
      clearInterval(timer);
    }
  }, [refetch]);

  React.useEffect(() => {
    setCounter(data?.countByStatus)
  }, [data, setCounter])


  return null;
}

export default TabTitleCounter

function extractDate(dt) {
  try {
    const d = new Date(dt);
    let year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
    let month = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d);
    let day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
    return `${year}-${month}-${day}`;
  } catch (ex) {
    return dt;
  }
}
