import cache from 'core/services/apolloClientCache';
import { nanoid } from 'nanoid';
import { GET_ORDER } from 'core/graphql/queries';
import { addItem, updateItem, removeItem, updateOrder } from './syncCache';

const defaultNewItem = {
  children: [],
  root: null,
  boxItemId: null,
  scanned: false,
  printed: false,
  icePacks: null,
  weight: null,
  labelAws: null
};

export const addProductToOrderOffline = (orderId, product, boxItemId) => {
  const orderItemId = nanoid();

  const newItem = {
    ...defaultNewItem,
    product,
    orderItemId,
    boxItemId: boxItemId ?? null
  };

  addItem(orderId, orderItemId, newItem);

  cache.updateQuery({ query: GET_ORDER, variables: { id: orderId } }, (data) => {
    const items = boxItemId ?
      data.getOrder.items.map((item) => {
        if (item.orderItemId === boxItemId) {
          const children = [...item.children, orderItemId];
          updateItem(orderId, boxItemId, { children });
          return { ...item, children };
        }
        return item;
      })
      : data.getOrder.items;

    return {
      getOrder: {
        ...data.getOrder,
        items: [...items, newItem]
      }
    };
  });
  return orderItemId;
};


export const removeBoxFromOrderOffline = (orderId, boxItemId) => {
  cache.updateQuery({ query: GET_ORDER, variables: { id: orderId } }, (data) => {
    const foundBox = data.getOrder.items.find(item => item.orderItemId === boxItemId);
    if (foundBox) {
      const boxChildren = foundBox.children || [];
      const items = data.getOrder.items.map((item) => {
        if (boxChildren.includes(item.orderItemId)) {
          if (item?.product?.category?.name === 'packaging') {
            removeItem(orderId, item.orderItemId);
            return null;
          }
          updateItem(orderId, item.orderItemId, { boxItemId: null });
          return { ...item, boxItemId: null };
        }
        return item;
      }).filter((item) => !!item && item.orderItemId !== boxItemId);

      removeItem(orderId, boxItemId);
      return {
        getOrder: {
          ...data.getOrder,
          items
        }
      }
    }
    return data;
  });
};

export const addItemToBoxOffline = (orderId, orderItemId, boxItemId) => {
  cache.updateQuery({ query: GET_ORDER, variables: { id: orderId } }, (data) => {
    const foundBox = data.getOrder.items.find(item => item.orderItemId === boxItemId);
    const foundItem = data.getOrder.items.find(item => item.orderItemId === orderItemId);

    if (foundBox && foundItem) {
      const items = data.getOrder.items.map((item) => {
        if (item.orderItemId === foundBox.orderItemId) {
          const children = [...item.children, orderItemId];
          updateItem(orderId, item.orderItemId, { children });
          return { ...item, children };
        }
        if (item.orderItemId === foundItem.orderItemId) {
          updateItem(orderId, item.orderItemId, { boxItemId });
          return { ...item, boxItemId };
        }
        return item;
      });
      return {
        getOrder: {
          ...data.getOrder,
          items
        }
      }
    }
    return data;
  });
};

export const removeItemFromBoxOffline = (orderId, orderItemId, boxItemId) => {
  cache.updateQuery({ query: GET_ORDER, variables: { id: orderId } }, (data) => {
    const foundBox = data.getOrder.items.find(item => item.orderItemId === boxItemId);
    const foundItem = data.getOrder.items.find(item => item.orderItemId === orderItemId);
    if (foundBox && foundItem) {
      const items = data.getOrder.items.map((item) => {
        if (item.orderItemId === foundBox.orderItemId) {
          const children = item.children.filter(id => id !== orderItemId);
          updateItem(orderId, item.orderItemId, { children });
          return { ...item, children };
        }
        if (item.orderItemId === foundItem.orderItemId) {
          if (item?.product?.category?.name === 'packaging') {
            removeItem(orderId, item.orderItemId);
            return null;
          }
          updateItem(orderId, item.orderItemId, { boxItemId: null });
          return { ...item, boxItemId: null };
        }
        return item;
      }).filter(Boolean);
      return {
        getOrder: {
          ...data.getOrder,
          items
        }
      }
    }
    return data;
  });
};


export const replaceBoxOffline = (orderId, product, boxItemId) => {
  const newBoxItemId = nanoid();

  const newBox = {
    ...defaultNewItem,
    product,
    orderItemId: newBoxItemId
  };

  cache.updateQuery({ query: GET_ORDER, variables: { id: orderId } }, (data) => {
    const foundOldBox = data.getOrder.items.find(item => item.orderItemId === boxItemId);
    if (foundOldBox && foundOldBox?.product?.id !== product.id) {
      const oldChildren = foundOldBox.children || [];
      const items = data.getOrder.items
        .filter(item => item.orderItemId !== boxItemId)
        .map(item => {
          if (oldChildren.includes(item.orderItemId)) {
            updateItem(orderId, item.orderItemId, { boxItemId: newBoxItemId });
            return { ...item, boxItemId: newBoxItemId };
          }
          return item;
        });
      addItem(orderId, newBoxItemId, { ...newBox, children: oldChildren });
      removeItem(orderId, boxItemId);
      return {
        getOrder: {
          ...data.getOrder,
          items: [...items, { ...newBox, children: oldChildren }]
        }
      }
    }
    return data;
  });
};

export const updateOrderItemOffline = (orderId, orderItemId, itemData) => {
  let ret;
  cache.updateQuery({ query: GET_ORDER, variables: { id: orderId } }, (data) => {
    ret = data;
    const foundItem = data.getOrder.items.find(item => item.orderItemId === orderItemId);
    if (foundItem) {
      const items = data.getOrder.items.map((item) => {
        if (item.orderItemId === foundItem.orderItemId) {
          updateItem(orderId, item.orderItemId, { ...itemData });
          return { ...item, ...itemData };
        }
        return item;
      });
      ret = {
        getOrder: {
          ...data.getOrder,
          items
        }
      }
    }
    return ret;
  })
  return ret?.getOrder;
}

export const updateOrderOffline = (orderId, orderData) => {
  updateOrder(orderId, orderData);
  cache.updateQuery({ query: GET_ORDER, variables: { id: orderId } }, (data) => {
    return {
      getOrder: {
        ...data.getOrder,
        ...orderData
      }
    }
  })
}
