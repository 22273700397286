import React from 'react';
import { Hub, Auth } from 'aws-amplify';
import { useSetRecoilState } from 'recoil';

import { currentUser } from './atoms';

export const AuthState = () => {
  const setUser = useSetRecoilState(currentUser);

  const fillUserData = React.useCallback(() => {
    Auth.currentAuthenticatedUser().then(data => {
      if (data) {
        setUser(data.username)
      } else {
        setUser(null)
      }
    }).catch(() => setUser(null))
  }, [setUser]);

  React.useEffect(() => {
    Hub.listen('auth', (data) => {
      const { event } = data.payload;
      if (['signIn', 'signUp'].includes(event)) {
        fillUserData();
      }
      if (event === 'signOut') {
        fillUserData(null);
      }
    });
    return () => Hub.remove('auth')
  }, [fillUserData, setUser]);

  React.useEffect(() => {
    fillUserData();
  }, [fillUserData]);

  return null;
}